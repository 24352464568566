export const ROUTERS = {
  HOME: "/home",
  BOOKING: "/booking",
  JOURNEY: "/journey",
  SHOPPING: "/shopping",
  CHECKOUT: "/checkout",
  FOOD: "/food",
  SHOPPING_CART: "/shopping-cart",
  danger: "DANGER",
  custom: "CUSTOM",
  PRODUCT_DETAIL: "/shopping/product/:id",
  SHOPPING_MERCHANT: "/shopping/merchant/:id",
  SHOPPING_SEARCH: "/shopping/search",
  PURCHASE_HISTORY: "/orders",
  ORDER_DETAILS: "/order-details/:id",
  MERCHANT_SEARCH: "/merchant-page/:id/search",
  MERCHANT_CATEGORIES: "/merchant-page/categories",
  ORDER_CART: "/order",
  REQUEST_PAGE: "/shopping/request-open-shop",
  SHARE_TOKEN: "/share-token",
  FLASH_SALE: "/shopping/flash-sale",
  MOST_PAGE: "/shopping/most-page",
  PAGE_NOT_FOUND: "/page-not-found",
  PAYMENT_COMPLETE: "/payment-complete",
  PAYMENT: "/payment",
  WELCOME:"/welcome",
}

export const ROUTERS_NOT_LAYOUT = [ROUTERS.SHARE_TOKEN, ROUTERS.REQUEST_PAGE, ROUTERS.WELCOME]
