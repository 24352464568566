import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { signIn } from "src/features/auth"
import { RECEIVE_TOKEN_URL, STATE_RENDER_APP } from "src/constants/common"
import { ShareTokenTypes, isIFrame } from "./types"
import { getUserInfo } from "src/services/user-service"
import i18n from "src/locales"
import {
  updateCurrency,
  validateAndFetchCurrency,
} from "src/utils/convert-currency"
import { ProgressSpinner } from "primereact/progressspinner"

function ShareToken(props: ShareTokenTypes) {
  const { setStateRenderView, stateRenderView } = props
  const [isRender, setIsRender] = useState<boolean>(true)
  const [isHaveLogin, setIsHaveLogin] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener("message", handleReceiveMessage)
    return () => window.removeEventListener("message", handleReceiveMessage)
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReceiveMessage = (e: any) => {
    if (e?.data?.type === "authorized") { //authorized
      console.log("authorized")
      localStorage.removeItem("iframeError")
      const accessTokenData = e.data?.token?.accessToken
      const profileData = JSON.parse(e.data?.profile)
      let data = undefined
      if (accessTokenData && profileData?.id) {
        data = {
          user: profileData,
          access_token: accessTokenData,
        }

        localStorage.setItem("accessToken", accessTokenData)
        localStorage.setItem("userId", profileData?.id)
      }
      dispatch(signIn(data))
      setIsHaveLogin(true)
    } else if(e?.data?.type === "unauthorized") {
      localStorage.removeItem("iframeError")
      localStorage.clear()
      window.open(`${process.env.REACT_APP_HOME_DOMAIN_URL}/login`, "_self")
    }
  }
  const handleOnLoad = () => {
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      console.log("Get token")
      frame.contentWindow.postMessage({ type: "connect" }, "*")
      localStorage.setItem("iframeError", "true")
      detectIframeError()
    }
  }
  const getCurrencyData = async () => {
    await validateAndFetchCurrency(true)
    setStateRenderView(STATE_RENDER_APP.APP)
  }

  const getUserInfoData = async () => {
    const userId = localStorage.getItem("userId")
    // not have token
    if (!userId) {
      dispatch(signIn(undefined))
      setStateRenderView(STATE_RENDER_APP.APP)
      return
    }
    
    getUserInfo(userId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data) {
          const langResult = res.data.data?.language
            ? res.data.data.language
            : "vn"
          const currentCurrency = res.data.data?.currency
            ? res.data.data?.currency
            : "USD"
          i18n.changeLanguage(langResult.toLowerCase())
          updateCurrency(currentCurrency)
          getCurrencyData()
        } else {
          setStateRenderView(STATE_RENDER_APP.APP)
        }
      })
      .catch(() => setStateRenderView(STATE_RENDER_APP.APP))
  }
  useEffect(() => {
    if (isHaveLogin) getUserInfoData()
  }, [isHaveLogin])

  const detectIframeError = () => {
    setTimeout(() => {
      if(localStorage.getItem("iframeError")?.length) {
        localStorage.clear()
        window.open(`${process.env.REACT_APP_HOME_DOMAIN_URL}/login`, "_self")
      }
    }, 5000)
  }

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      localStorage.setItem("isLoading", "true")
    }
    localStorage.removeItem("iframeError")
    setIsRender(true)
  }, [])

  // clear loading when render app
  useEffect(() => {
    if(stateRenderView === STATE_RENDER_APP.APP) localStorage.removeItem("isLoading")
  }, [stateRenderView] )

  useEffect(() => {
    if(isRender) {
      detectIframeError()
    }
  },[isRender])

  if (!isRender) return null
  return (
    <div className={`${stateRenderView === STATE_RENDER_APP.APP ? "fixed top-[0px] left-[0px] z-10" : ""}`}>
      {localStorage.getItem("isLoading")?.length ? (
        <div className="h-screen w-screen">
          <div className="flex h-full w-full items-center justify-center gap-2 rounded-3 bg-white">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        </div>
      ) : null}
      <iframe
        id="socialLogin"
        style={{
          width: 0,
          height: 0,
          position: "absolute",
          left: 0,
          top: 0,
          display: "none",
          visibility: "hidden",
        }}
        width={0}
        height={0}
        title="socialLogin"
        src={RECEIVE_TOKEN_URL}
        onLoad={handleOnLoad}
      />
    </div>
  )
}

export default ShareToken
