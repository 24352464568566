import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import ImgCustom from "../img-custom"
import avatarDefault from "src/assets/images/avatar-default.webp"
import { getLogoApp } from "src/utils/app-logo"
import { getUserInfo } from "src/services/user-service"
import i18n from "src/locales"
import { userType } from "src/features/auth/props"
import { signIn, signOut } from "src/features/auth"
import { Menu } from "primereact/menu"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import LogoutModal from "../modal/logout-modal"
import { isIFrame } from "../share-token/types"

export default function HeaderView() {
  const authStore = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isShowSignOut, setIsShowSignOut] = useState<boolean>(false)
  const userMenuRef = useRef<Menu>(null)
  const goHome = () => {
    window.open(process.env.REACT_APP_HOME_DOMAIN_URL, "_self")
  }

  const getUser = () => {
    const userId = localStorage.getItem("userId")
    if(!userId) return
    getUserInfo(userId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {        
        if (res?.data?.data) {
          const langResult = res.data.data?.language
            ? res.data.data.language
            : "vn"
          i18n.changeLanguage(langResult.toLowerCase())
          const authData = {
            user: res?.data?.data as userType,
            access_token: localStorage.getItem("accessToken") || "",
          }
          dispatch(signIn(authData))
        }
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    getUser()
  },[])

  const handleSignOut = () => {
    setIsShowSignOut(false)
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: "disconnect",
        data: {
          token: localStorage.getItem("accessToken"),
          user_id: localStorage.getItem("userId"),
        },
      }
      console.log("Message disconnect")
      frame.contentWindow.postMessage(messageData, "*")
    }
    dispatch(signOut())
    localStorage.clear()
    window.open(`${process.env.REACT_APP_HOME_DOMAIN_URL}/login`, "_self")
  }

  const items = [
    {
      template: () => {
        return (
          <div className="flex flex-col">
            <div className="flex cursor-pointer flex-col border-t-[1px] border-t-gray-300">
              <div
                onClick={() => setIsShowSignOut(true)}
                className="flex items-center gap-1 px-2 py-[9px] text-[16px] leading-[24px] text-red-600 hover:bg-blue-50"
              >
                <i className="sctr-icon-log-out-03" />
                <span>{capitalizedFirst(t("header-setting.sign-out"))}</span>
              </div>
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <div className="flex items-center justify-between bg-blue-600 px-3 md:px-4 py-1">
      <div className="flex items-center cursor-pointer" onClick={goHome}>
        <img className="h-26px w-[26px]" src={getLogoApp()} alt="appLogo" />
        <span className="md:block hidden ml-[12px] text-[23px] font-bold leading-[36px] text-white">
          {process.env.REACT_APP_TITLE}
        </span>
        <span className="ml-[12px] md:block hidden text-[18px] font-medium leading-[28px] text-white ">
          {"Center"}
        </span>
      </div>
      <div className="flex items-center">
        <span className="ml-3 text-[14px] font-medium leading-[20px] text-white">
          {authStore?.value?.user?.full_name}
        </span>
        <div className="ml-[12px]">
          {!authStore?.value ? (
            <img
              alt="avatarDefault"
              src={avatarDefault}
              className="h-[32px] w-[32px] rounded-full object-cover object-center"
            />
          ) : (
            <>
              <div onClick={(e) => userMenuRef?.current?.toggle(e)} className="cursor-pointer h-[32px] w-[32px] rounded-full border-[2px] border-orange-500">
                <ImgCustom
                  isAvatar={true}
                  alt="userAvatar"
                  url={authStore.value.user?.avatar_thumbnail_url as string}
                  className="rounded-full object-cover object-center"
                />
              </div>
              <Menu model={items} popup ref={userMenuRef} className="mt-1 w-[300px]" />
              {isShowSignOut && (
                <LogoutModal
                  isShow={isShowSignOut}
                  title={capitalizedFirst(t("header.sign-out-modal"))}
                  message=""
                  handleClose={() => setIsShowSignOut(false)}
                  handleConfirm={handleSignOut}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
