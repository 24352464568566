import { configureStore } from "@reduxjs/toolkit"
// import ReduxLogger from "redux-logger"
import counterReducer from "../features/counter/counter-slice"
import loadReducer from "../features/load-example/load-slice"
import authReducer from "../features/auth"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"

const authConfig = {
  key: "authStore",
  storage,
}

const persistedAuthReducer = persistReducer(authConfig, authReducer)

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    load: loadReducer,
    auth: persistedAuthReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    // ReduxLogger,
  ],
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
