import React, { useMemo, useRef, useState } from "react"
import { ImgCustomProps } from "./props"
import "./index.scss"
import imageNotFound from "../../assets/images/image-not-found.png"
import avatarDefault from "src/assets/images/avatar-default.webp"
import shopDefault from "src/assets/images/defaultshop.webp"
import { getImageUrl } from "src/services/file-service"

// const getBase64StringFromDataURL = (dataURL: string) =>
//   dataURL.replace("data:", "").replace(/^.+,/, "")

const ImgCustom = (props: ImgCustomProps) => {
  const { alt, className, url, type, isAvatar, isShopImage } = props
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const imgRef = useRef<HTMLImageElement>(null)

  const getStyleImage = useMemo(() => {
    return className ? className : "h-full"
  }, [className])

  const handleLoadError = () => {
    if (imgRef.current?.src) {
      let imageDefault = imageNotFound
      if(isAvatar) imageDefault = avatarDefault
      if(isShopImage) imageDefault = shopDefault
      imgRef.current.src = imageDefault
      setIsLoaded(false)
    }
  }

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div
        className={`loader rounded-[50%] border-r-[2px] border-t-[2px] border-gray-700 ${
          isLoaded ? "opacity-1 h-[20px] w-[20px]" : "hidden"
        }`}
      />
      <img
        className={
          !isLoaded ? `opacity-1 ${getStyleImage}` : "h-[0px] w-[0px] opacity-0"
        }
        ref={imgRef}
        alt={alt}
        src={getImageUrl(url, type)}
        onLoad={() => setIsLoaded(false)}
        onError={handleLoadError}
      />
    </div>
  )
}

export default ImgCustom
