/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import { Dialog } from "primereact/dialog"
import { ShopAdminModalType, ShopAdminType } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import EmptyPage from "src/pages/empty-page"
import { ShopControllerApi } from "@soctrip/axios-shop-service"
import ShopAdminTable from "./shop-admin-table"
import Loading from "./loading"
import "./index.scss"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { InputText } from "primereact/inputtext"
import { useDebounce } from "use-debounce"
import { useToast } from "src/hooks/use-toast"

export default function ShopAdminModal(props: ShopAdminModalType) {
  const { t } = useTranslation()
  const { isShow, handleClose } = props
  const [shops, setShops] = useState<ShopAdminType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isHaveShop, setIsHaveShop] = useState<boolean>(true)
  const [search, setSearch] = useState<string>("")
  const [isRender, setIsRender] = useState<boolean>(false)
  const [searchDebounce] = useDebounce(search, 800)
  const showToast = useToast()
  const handleRequestOpenShop = () => {
    if(isHaveShop) return
    const urlRedirect = `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/request-open-shop`
    window.open(urlRedirect, "_self")
  }
  const handleSearch = (newValue: string) => {
    setSearch(newValue)
  }
  useEffect(() => {
    if (isRender) handleSearchValue()
  }, [searchDebounce])
  useEffect(() => {
    setIsRender(true)
  }, [])

  const handleSearchValue = () => {
    getShops()
  }
  const FooterContent = (
    <div className="mt-3 flex justify-end text-16 font-semibold leading-24 gap-1">
      {!isHaveShop ? <button
        className="rounded-3 border border-gray-300 px-18px py-10px text-white bg-blue-600 hover:bg-blue-500"
        onClick={handleRequestOpenShop}
      >
        {capitalizedFirst(t("home-page.request-open"))}
      </button> : null}
      <button
        className="rounded-3 border border-gray-300 px-18px py-10px text-gray-700 hover:bg-gray-100"
        onClick={() => handleClose()}
      >
        {capitalizedFirst(t("global.close"))}
      </button>
    </div>
  )
  const getShops = () => {
    setIsLoading(true)
    setShops([])
    const filterString = search ? `name@=${search}` : undefined
    new ShopControllerApi(configHeader(SERVICE.SHOP)).shopsShopUserGet(0,100,filterString).then((res: any) => {
      if(res?.data?.data) {
        if(res.data.data?.userInfoDTOS?.length) {
          const result: ShopAdminType[] = []
          res.data.data.userInfoDTOS.forEach((i: any) => {
            result.push(i)
          })
          setShops(result)
        }
        setIsHaveShop(!!res.data.data?.is_shop)
      }
    }).catch(() => {
      handleClose()
      showToast({
        detail: capitalizedFirst(t("home-page.error-shop")),
        severity: "error",
      })
    }).finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getShops()
  }, [])

  if (!isShow) return null
  return (
    <Dialog
      id="shopAdminModal"
      draggable={false}
      header={capitalizedFirst(t("home-page.shop-admin"))}
      visible={isShow}
      footer={FooterContent}
      onHide={() => handleClose()}
    >
      <div className="flex min-h-[200] md:min-h-[300px] w-full flex-col items-center md:w-[700px]">
        {isLoading ? <div className="flex items-center justify-center w-full h-full m-auto"><Loading /></div> : null}
        {!shops.length && !isLoading ? <EmptyPage message={capitalizedFirst(t("global.no-data-found"))}/> : null}
        {shops.length && !isLoading ? (
          <div className="w-full flex flex-col gap-2">
            <div className="pt-4px">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  className="w-full md:w-[367px] h-[44px]"
                  placeholder={capitalizedFirst(t("global.search"))}
                  value={search}
                  onChange={(event) => handleSearch(event.target.value)}
                />
              </span>
            </div>
            <ShopAdminTable shops={shops} />
          </div>
        ) : null}
      </div>
    </Dialog>
  )
}
