import http from "src/config/interceptors"

export const getUserInfo = (userId: string) => {
  return http.get(`/user/users/${userId}`)
}

export const detectUserAdmin = (userId: string) => {
  return http.get(
    `/user-permission/api-permissions/users/${userId}/permissions`
  )
}
