import React, { useEffect, useRef, useState } from "react"
import "./App.scss"
import MainLayout from "./components/layout/main-layout"
import { Navigate, useLocation, useRoutes } from "react-router-dom"
import { Toast } from "primereact/toast"
import { ToastContext } from "./app/toast-context"
import { ROUTERS, ROUTERS_NOT_LAYOUT } from "./constants/router"
import { STATE_RENDER_APP } from "./constants/common"
import PageNotFound from "./pages/404"
import ShareToken from "./components/share-token"
import HomePage from "./pages/home-page"
import { useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import WelComePage from "./pages/welcome"

function App() {
  const authStore = useSelector((state: RootState) => state.auth)
  const routes = useRoutes([
    {
      path: ROUTERS.HOME,
      element: <HomePage />,
    },
    {
      path: ROUTERS.PAGE_NOT_FOUND,
      element: <PageNotFound />,
    },
    {
      path: ROUTERS.WELCOME,
      element: <WelComePage />,
    },
    {
      path: "/",
      element: <Navigate to={ROUTERS.HOME} />,
    },
    { path: "*", element: <Navigate to={ROUTERS.PAGE_NOT_FOUND} /> },
  ])

  const [stateRenderView, setStateRenderView] = useState<string>(
    STATE_RENDER_APP.INIT
  )
  const detectLogin = () => {
    setStateRenderView(STATE_RENDER_APP.IFRAME)
  }
  useEffect(() => {
    function initView() {
      detectLogin()
    }
    initView()
  }, [])

  const location = useLocation()

  useEffect(() => {
    if (stateRenderView === STATE_RENDER_APP.APP && !authStore.value && !ROUTERS_NOT_LAYOUT.includes(location.pathname)) {
      // window.open(`${process.env.REACT_APP_HOME_DOMAIN_URL}/login`, "_self")
    }
  }, [stateRenderView, authStore, location])

  const toast = useRef<Toast>(null)
  if (stateRenderView === STATE_RENDER_APP.INIT) return null
  return (
    <ToastContext.Provider value={toast}>
      {stateRenderView === STATE_RENDER_APP.APP ? (
        <MainLayout onSearch={(keyword) => console.log("searching", keyword)}>
          {routes}
        </MainLayout>
      ) : null}
      < ShareToken 
        isLoading={true}
        stateRenderView={stateRenderView}
        setStateRenderView={(newValue: string) =>
          setStateRenderView(newValue)
        }
      />
      <Toast ref={toast} />
    </ToastContext.Provider>
  )
}

export default App
