import React from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"

export default function Loading() {
  const { t } = useTranslation()
  return (
    <div className="flex h-full w-full items-center justify-center gap-2 rounded-3 bg-white">
      <p className="animate-pulse duration-75">
        {capitalizedFirst(t("loading"))}
      </p>
      <i className="pi pi-spin pi-spinner text-24 font-bold text-blue-light-400"></i>
    </div>
  )
}
