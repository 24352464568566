import React, { useMemo } from "react"
import "./main-layout.scss"
import type { MainLayoutProps } from "./main-layout-props"
import HeaderView from "../header-view"
// import appLogo from "src/assets/images/logo.svg"
// import { isDevMode } from "src/utils/app-logo"
import { ROUTERS_NOT_LAYOUT } from "src/constants/router"
import { useLocation } from "react-router-dom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
export type { MainLayoutProps }

export default function MainLayout(props: MainLayoutProps) {
  const location = useLocation()
  const {t} = useTranslation()
  const isNotLayout = useMemo(() => {
    return (
      ROUTERS_NOT_LAYOUT.includes(location.pathname)
    )
  }, [location])

  const footerData = useMemo(() => {
    return [
      {
        label: capitalizedFirst(t("home-page.footer-data.agency")),
        link: "",
      },
      {
        label: capitalizedFirst(t("home-page.footer-data.policy")),
        link: "",
      },
      {
        label: capitalizedFirst(t("home-page.footer-data.condition")),
        link: "",
      },
      {
        label: capitalizedFirst(t("home-page.footer-data.cookie")),
        link: "",
      },
    ]
  }, [])
  const clickItem = (link: string) => {
    if (link) window.open(link, "_blank")
  }

  if (isNotLayout) return <>{props.children}</>
  return (
    <div
      className="no-scrollbar mx-auto flex h-full w-screen flex-col overflow-auto"
      id="content-body"
    >
      <HeaderView />
      <main className="my-4 h-screen w-full ">
        <div className="mx-auto w-full max-w-[1200px]">{props.children}</div>
      </main>
      {/* {
        !isDevMode() ? (
          <div className="flex gap-1 items-center fixed bottom-40px right-1 z-50">
            <a
              href="https://forms.gle/eX9Jb2eDMPuw6Hky9"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 px-[4px] py-[4px] rounded-[100px] shadow-lg border border-gray-100 cursor-pointer bg-white hover:bg-gray-25 group"
            >
              <span className="text-12 font-semibold group-hover:ml-2 hidden group-hover:block"
              >Give feedback</span>
              <img className="h-40px w-40px" src={appLogo} alt="Logo App" />
            </a>
          </div>
        ) : null
      } */}
      {/* Footer Right */}
      <div className="mx-auto w-full px-[16px] md:px-[unset] md:w-[1200px] flex flex-col md:flex-row items-center justify-between text-[14px] leading-[20px] font-medium">
        <div className="text-gray-500">{capitalizedFirst(t("home-page.footer-data.copy-right"))}</div>
        <div className="flex flex-col md:flex-row items-center text-gray-700 my-[16px] gap-[24px]">
          {footerData.map(i => (
            // <a rel="noopener noreferrer" target="_blank" href={i.link} className="cursor-pointer hover:underline" onClick={() => clickItem(i.link)} key={i.label}>
            //   {i.label}
            // </a>
            <div className="cursor-pointer hover:underline" onClick={() => clickItem(i.link)} key={i.label}>
              {i.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
